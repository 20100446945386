import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import onPageRender from '../hocs/onPageRender'
import CategoryIntro from '../components/CategoryIntro/CategoryIntro'
import Container from '../components/Container/Container'
import Slices from '../components/Slices/Slices'
import SubcategoryNav from '../components/SubcategoryNav/SubcategoryNav'
import ProductGridBackground from '../components/ProductGridBackground/ProductGridBackground'
import Seo from '../components/Seo/Seo'

const getParentCategory = (id, categoryData = { edges: [] }) => {
  const edge = categoryData.edges
    .filter(({ node: { data: { subcategories } } }) =>
      subcategories.every(({ subcategory: { document } }) => !!document)
    )
    .find(({ node: { data: { subcategories } } }) =>
      subcategories.some(
        ({
          subcategory: {
            document: { id: documentId },
          },
        }) => documentId === id
      )
    )

  return {
    categoryTitle: edge?.node?.data?.title?.text || '',
    subcategories: edge?.node?.data?.subcategories || [],
    categoryPagePath: edge?.node?.fields?.pagePath || '',
  }
}

const Subcategory = ({ data, addVariantToCart }) => {
  const {
    allPrismicCategory,
    allShopifyProduct,
    prismicSubcategory: {
      id,
      _previewable,
      data: {
        title: { text: title },
        description: { text: description },
        body = [],
        url: previewPagePath,
      },
      fields: { pagePath } = {},
    },
  } = data

  const isPreview = !!_previewable

  let categoryTitle
  let subcategories
  let categoryPagePath

  if (!isPreview) {
    const parentCategoryData = getParentCategory(id, allPrismicCategory)
    categoryTitle = parentCategoryData.categoryTitle
    subcategories = parentCategoryData.subcategories
    categoryPagePath = parentCategoryData.categoryPagePath
  }

  return (
    <>
      <Seo title={`${title} | ${categoryTitle}`} description={description} />
      <Container>
        <CategoryIntro
          title={title}
          description={description}
          pagePath={previewPagePath || pagePath}
          nav={
            !isPreview && (
              <SubcategoryNav
                categoryTitle={categoryTitle}
                categoryPagePath={categoryPagePath}
                subcategories={subcategories}
              />
            )
          }
        />
      </Container>
      <Container>
        <Slices
          body={body}
          allShopifyProduct={allShopifyProduct}
          addVariantToCart={addVariantToCart}
        />
      </Container>
      <ProductGridBackground />
    </>
  )
}

Subcategory.propTypes = {
  data: PropTypes.object.isRequired,
  addVariantToCart: PropTypes.func.isRequired,
}

export default onPageRender(Subcategory)

export const query = graphql`
  query($id: String!, $lang: String!) {
    prismicSubcategory(id: { eq: $id }) {
      ...PrismicSubcategoryData
    }
    allPrismicCategory(filter: { lang: { eq: $lang } }) {
      edges {
        node {
          data {
            title {
              text
            }
            subcategories {
              subcategory {
                document {
                  ... on PrismicSubcategory {
                    id
                    data {
                      title {
                        text
                      }
                    }
                    fields {
                      pagePath
                    }
                  }
                }
              }
            }
          }
          fields {
            pagePath
          }
        }
      }
    }
    allShopifyProduct {
      edges {
        node {
          shopifyId
          variants {
            sku
            price {
              amount
            }
            shopifyId
            availableForSale
          }
        }
      }
    }
  }
`
