import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

const ProductGridBackground = props => {
  return (
    <div
      className="fixed top-0 bottom-0 left-0 mt-32 w-1/2 pointer-events-none"
      style={{ opacity: '0.35', zIndex: '-1' }}
    >
      <StaticImage
        src="./assets/category-grid-background-2.png"
        alt=""
        className="static block select-none"
        placeholder="none"
        objectFit="fill"
        imgStyle={{ width: '100%', height: '100%' }}
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  )
}

export default ProductGridBackground
